import { cn } from "@nephroflow/design-system/styling/utils";
import * as SheetPrimitive from "@radix-ui/react-dialog";
import { Slot } from "@radix-ui/react-slot";
import * as React from "react";

import { IconButton } from "./button";
import { CloseIcon, DoneIcon } from "./icon";

const SheetTrigger = SheetPrimitive.Trigger;
const SheetClose = SheetPrimitive.Close;

const SetSheetOpenContext = React.createContext<React.Dispatch<React.SetStateAction<boolean>>>(() => {});

function useCloseSheet() {
  const setOpen = React.useContext(SetSheetOpenContext);
  return React.useCallback(() => setOpen(false), [setOpen]);
}

const SheetRoot = ({
  children,
  ...props
}: Omit<React.ComponentPropsWithoutRef<typeof SheetPrimitive.Root>, "open" | "onOpenChange" | "modal">) => {
  const [open, setOpen] = React.useState(false);
  return (
    <SheetPrimitive.Root {...props} open={open} onOpenChange={setOpen} modal>
      <SetSheetOpenContext.Provider value={setOpen}>{children}</SetSheetOpenContext.Provider>
    </SheetPrimitive.Root>
  );
};
SheetRoot.displayName = "SheetRoot";

const SheetOverlay = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof SheetPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <SheetPrimitive.Overlay
    className={cn(
      "motion-safe:data-[state=open]:duration-200 motion-safe:data-[state=open]:animate-in motion-safe:data-[state=open]:fade-in",
      "motion-safe:data-[state=closed]:duration-200 motion-safe:data-[state=closed]:animate-out motion-safe:data-[state=closed]:fade-out",
      "fixed inset-0 z-50 bg-blue-150/30",
      className,
    )}
    {...props}
    ref={ref}
  />
));
SheetOverlay.displayName = SheetPrimitive.Overlay.displayName;

const SheetSheet = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof SheetPrimitive.Content> & {
    lightDismiss?: boolean;
  }
>(({ className, children, lightDismiss = false, onInteractOutside, onEscapeKeyDown, ...props }, ref) => {
  return (
    <SheetPrimitive.Portal>
      <SheetOverlay />
      <SheetPrimitive.Content
        ref={ref}
        className={cn(
          "fixed inset-y-0 right-0 z-50 flex h-full w-[calc(100%_-_8rem)] max-w-[108rem] flex-col bg-white",
          "motion-safe:data-[state=open]:duration-400 motion-safe:data-[state=open]:animate-in motion-safe:data-[state=open]:fade-in motion-safe:data-[state=open]:slide-in-from-right-1/4",
          "motion-safe:data-[state=closed]:duration-200 motion-safe:data-[state=closed]:animate-out motion-safe:data-[state=closed]:fade-out motion-safe:data-[state=closed]:slide-out-to-right-1/4",
          className,
        )}
        onInteractOutside={(event) => {
          if (!lightDismiss) event.preventDefault();
          onInteractOutside?.(event);
        }}
        onEscapeKeyDown={(event) => {
          if (!lightDismiss) event.preventDefault();
          onEscapeKeyDown?.(event);
        }}
        {...props}
      >
        {children}
      </SheetPrimitive.Content>
    </SheetPrimitive.Portal>
  );
});
SheetSheet.displayName = SheetPrimitive.Content.displayName;

const SheetTitle = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof SheetPrimitive.Title>
>(({ className, ...props }, ref) => (
  <SheetPrimitive.Title
    ref={ref}
    className={cn(
      "col-start-1 col-end-4 row-start-1 row-end-2 flex items-center justify-center border-b border-b-gray-10 px-22 py-4 text-2xl font-medium leading-5",
      className,
    )}
    {...props}
  />
));
SheetTitle.displayName = SheetPrimitive.Title.displayName;

function SheetLayout({ asChild = false, children }: { asChild?: boolean; children: React.ReactNode }) {
  const Comp = asChild ? Slot : "div";
  return (
    <Comp className="grid h-full grid-cols-[11rem_1fr_11rem] grid-rows-[auto_1fr] overflow-hidden">{children}</Comp>
  );
}

function SheetHeaderLeftSlot({ className, children }: { className?: string; children: React.ReactNode }) {
  return (
    <div className={cn("col-start-1 col-end-2 row-start-1 row-end-2 flex items-start justify-center pt-4", className)}>
      {children}
    </div>
  );
}

function SheetHeaderRightSlot({ className, children }: { className?: string; children: React.ReactNode }) {
  return (
    <div className={cn("col-start-3 col-end-4 row-start-1 row-end-2 flex items-start justify-center pt-4", className)}>
      {children}
    </div>
  );
}

const SheetContent = React.forwardRef<HTMLDivElement, { children: React.ReactNode; className?: string }>(
  ({ children, className, ...props }, ref) => {
    return (
      <div
        className={cn(
          "col-start-1 col-end-4 row-start-2 row-end-3 overflow-x-hidden overflow-y-scroll px-6 py-4",
          className,
        )}
        {...props}
        ref={ref}
      >
        {children}
      </div>
    );
  },
);

SheetContent.displayName = "SheetContent";

function SheetCloseButton(props: Partial<React.ComponentPropsWithoutRef<typeof IconButton>>) {
  return (
    <SheetHeaderLeftSlot>
      <SheetClose asChild>
        <IconButton
          {...props}
          title="Close"
          impact="neutral"
          importance="secondary"
          className="z-10"
          icon={<CloseIcon />}
        />
      </SheetClose>
    </SheetHeaderLeftSlot>
  );
}

function SheetSubmitButton(props: Partial<React.ComponentPropsWithoutRef<typeof IconButton>>) {
  return (
    <SheetHeaderRightSlot>
      <IconButton
        {...props}
        title="Submit"
        type="submit"
        impact="neutral"
        importance="primary"
        className="z-10"
        icon={<DoneIcon />}
      />
    </SheetHeaderRightSlot>
  );
}

export {
  SheetRoot,
  SheetTrigger,
  SheetSheet,
  SheetLayout,
  SheetHeaderLeftSlot,
  SheetHeaderRightSlot,
  SheetTitle,
  SheetContent,
  SheetCloseButton,
  SheetSubmitButton,
  SheetClose,
  useCloseSheet,
};
